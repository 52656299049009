import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { OrgManageService } from '@/sevices';
import { UnitType, } from '../../../typings/organization/org-manage';
import { Loading } from '@cloudpivot-hermes/common-components';
import { namespace } from 'vuex-class';
const Organization = namespace('Organization');
let orgConfigDetail = class orgConfigDetail extends Vue {
    constructor() {
        super(...arguments);
        // 默认不可编辑
        this.isEdit = false;
        // 更新节流
        this.isUpdateLoading = false;
        // 显示密码框
        this.isShowPassword = false;
        this.isShowOrgListModal = false;
        this.loading = false;
        this.subOrgNames = [];
    }
    get curOrgId() {
        return this.$route.params.id;
    }
    get directStatus() {
        return !this.curOrgDetail?.status;
    }
    goBack() {
        this.$router.push({
            name: 'org-manage',
        });
    }
    /**
     * 获取当前组织信息
     */
    getOrgDetail() {
        return OrgManageService.getOrgDetail(this.curOrgId).then((res) => {
            if (res.success) {
                const curOrgDetail = this.generateOrgDetail(res.data);
                this.curOrgDetail = JSON.parse(JSON.stringify(curOrgDetail));
            }
        });
    }
    /**
     * 生成组织配置列表数据
     */
    generateOrgDetail(data) {
        if (!data)
            return;
        const parentOrg = [];
        if (data.parentDepartmentId) {
            parentOrg.push({
                id: data.parentDepartmentId,
                name: data.parentDepartmentName,
                type: UnitType.Dept,
            });
        }
        const orgDetailData = {
            id: data.id,
            corpId: data.corpId,
            name: data.name,
            parentOrg: parentOrg,
            status: !!data.status,
            userNum: data.userNum,
            deptNum: data.deptNum,
            createdTime: data.createdTime,
            sourceType: data.sourceType,
            dingTalk: this.getDingTalkData(data.sourceConfig || {}),
            wechat: this.getWechatData(data.sourceConfig || {}),
        };
        return orgDetailData;
    }
    /**
     * 获取对应钉钉集成的参数
     */
    getDingTalkData(data = {}) {
        return {
            corpId: data.corpId,
            corpSecret: data.corpSecret,
            agentId: data.agentId,
            appkey: data.appkey,
            appSecret: data.appSecret,
            scanAppId: data.scanAppId,
            scanAppSecret: data.scanAppSecret,
            pcServerUrl: data.pcServerUrl,
            synRedirectUri: data.synRedirectUri,
        };
    }
    /**
     * 获取对应钉钉集成的参数
     */
    getWechatData(data = {}) {
        return {
            corpId: data.corpId,
            corpSecret: data.corpSecret,
            agentId: data.agentId,
            appSecret: data.appSecret,
            pcServerUrl: data.pcServerUrl,
            synRedirectUri: data.synRedirectUri,
        };
    }
    created() {
        this.loading = true;
        this.getOrgDetail().finally(() => {
            this.loading = false;
        });
    }
    async getSubOrganization(status) {
        const res = await OrgManageService.getSubOrganization({
            id: this.curOrgDetail.id,
            status: status,
        });
        if (res.success) {
            this.curOrgDetail.status = !this.curOrgDetail.status;
        }
        return res;
    }
    /**
     * 编辑组织
    */
    editOrg() {
        this.isEdit = true;
    }
    async showSubInfo(callback) {
        const res = await this.getSubOrganization();
        if (!res.success)
            return;
        if (res.data?.length) {
            callback(res);
            this.subOrgNames = res.data?.map(item => item.name);
            this.isShowOrgListModal = true;
        }
        else {
            this.deleteOrg({ cb: callback });
        }
    }
    /**
     * 删除组织
    */
    delOrg() {
        this.isShowPassword = true;
        // this.deleteOrg();
    }
    /**
     * 删除组织
    */
    deleteOrg(data) {
        OrgManageService.deleteOrg(this.curOrgId).then((res) => {
            if (res.success) {
                data.cb(res);
                this.$message.success('删除成功');
                this.goBack();
                this.checkOrg(false);
            }
        });
    }
    /**
     * 取消编辑
    */
    async cancelEdit() {
        this.isEdit = false;
        await this.getOrgDetail();
        await this.$nextTick();
        this.$refs.orgDetail.cancel();
    }
    /**
     * 更新组织
    */
    submit() {
        this.isUpdateLoading = true;
        this.$refs.orgDetail.submit().then((res) => {
            if (res.success) {
                this.goBack();
            }
        }).finally(() => {
            this.isUpdateLoading = false;
        });
    }
};
__decorate([
    Organization.Action('checkOrg')
], orgConfigDetail.prototype, "checkOrg", void 0);
orgConfigDetail = __decorate([
    Component({
        name: 'orgConfigDetail',
        components: {
            Loading,
            OrgDetail: () => import('@/components/sys-manage/org-manage/orgdetail.vue'),
            Password: () => import('@/components/global/Password.vue'),
            OrgListModal: () => import('@/components/sys-manage/org-manage/org-list-modal.vue'),
        },
    })
], orgConfigDetail);
export default orgConfigDetail;
